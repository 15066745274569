import slick from 'slick-carousel';
import fancybox from '@fancyapps/fancybox';
import { popover, tooltip } from 'bootstrap';
import cookie from 'jquery.cookie';
import _ from 'lodash';
import ionRangeSlider from 'ion-rangeslider';

$(document).ready(function () {

    // menu mobilne
    (function () {
        var mobileMenu = $('.navmobile');
        var body = $('body');

        $('#mobileMenuOpen').on('click', function () {
            mobileMenu.addClass('active');
            body.css('overflow-y', 'hidden')
        });
        $('#mobileClose').on('click', function () {
            mobileMenu.removeClass('active');
            body.css('overflow-y', 'auto')
        });
    })();


    /* slider */
    $(".slider__carousel").slick({
        autoplay: true,
        autoplaySpeed: 6000,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 1500,
        // prevArrow: '<div class="prev"><i class="material-icons">navigate_before</i></div>',
        // nextArrow: '<div class="next"><i class="material-icons">navigate_next</i></div>',
        arrows: false,
        dots: false,
        centerMode: true,
        fade: true
    });

    $('.product__gallery-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.product__gallery-nav'
    });
    $('.product__gallery-nav').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: '.product__gallery-for',
        dots: false,
        arrows: false,
        centerMode: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 3
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    $('.logos__slider').slick({
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 1500,
        pauseOnFocus: false,
        pauseOnHover: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        centerMode: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 3
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    /* 
     * menu link active helper
     */
    $(".navbar .dropdown-item.active").parents("li").addClass("active");
    $(".navi-item-link.active").parents("li").children("a").addClass("active");

    /* FANCYBOX */
    $("a.fancybox").fancybox({
        loop: true,
        keyboard: true
    });

    /* COOKIE */
    var cookieInfo = $("#cookieInfo");
    if (cookieInfo.length) {
        var cookie = $.cookie("cookieinfo");
        if (cookie === '0') {
            $("#cookieInfo .cookieClose").on('click', function () {
                $.cookie("cookieinfo", '1', { path: '/', expires: 3650 });
                cookieInfo.animate({
                    'margin-top': - cookieInfo.outerHeight(true)
                }, 800, function () {
                    $("#cookieInfo").remove();
                });

            })
        }
    }

    imgToSvg($('img.svg'));

    (function () {
        scalePic(4, 3);
        $(window).on('resize', function () {
            scalePic(4, 3);
        });
    })();

    // konfiguracja rat we wniosku
    (function () {
        var period = 12;
        var paymentPrecent = 0;
        var finalPrecent = $("#finalPrecentInput").val();
        var productPrice = $("#leasingProductPrice").val();
        var productId = $("#leasingProductId").val();

        var rangeSliderPeriod = $(".range-slider-period");
        rangeSliderPeriod.ionRangeSlider({
            type: "single",
            skin: "round",
            min: rangeSliderPeriod.data('from'),
            max: rangeSliderPeriod.data('to'),
            from: rangeSliderPeriod.data('from'),
            step: 1,
            grid: true,
            prettify: function (n) {
                return n + ' m-cy'
            },
            onFinish: function (data) {
                period = data.from;
                updateLeasingInstallemnt(period, paymentPrecent, finalPrecent, productPrice, productId);
            }
        });

        var rangeSliderPayment = $(".range-slider-payment");
        rangeSliderPayment.ionRangeSlider({
            type: "single",
            skin: "round",
            min: rangeSliderPayment.data("from"),
            max: rangeSliderPayment.data("to"),
            from: rangeSliderPayment.data("from"),
            grid: true,
            step: 1,
            prettify: function (n) {
                return n + "%";
            },

            onFinish: function (data) {
                paymentPrecent = data.from;
                updateLeasingInstallemnt(period, paymentPrecent, finalPrecent, productPrice, productId);
            }
        });

        var rangeSliderFinalPrecent = $(".range-slider-finalprecent");
        $(".range-slider-finalprecent").ionRangeSlider({
            type: "single",
            skin: "round",
            min: rangeSliderFinalPrecent.data('from'),
            max: rangeSliderFinalPrecent.data('to'),
            from: rangeSliderFinalPrecent.data('from'),
            grid: true,
            step: 1,
            prettify: function (n) {
                return n + '%'
            },
            onFinish: function (data) {
                finalPrecent = data.from;
                updateLeasingInstallemnt(period, paymentPrecent, finalPrecent, productPrice, productId);
            }
        });
    })();

    //autocomplete
    (function () {
        var searchField = $(".top__search-input");
        var searchText = searchField.val();
        var autocompleteForm = $("form.top__search-form");
        var autocompleteBox = $(".autocomplete");
        var autocompleteLink = $(".autocomplete__link");

        searchField.on('focus', function () {
            if (autocompleteLink.length) {
                autocompleteBox.addClass('autocomplete--active');
            }
        });

        $("body").on('mouseup', function (e) {
            if (!autocompleteForm.is(e.target) && autocompleteForm.has(e.target).length === 0) {
                autocompleteBox.removeClass('autocomplete--active');
            }
        });

        searchField.on('keyup', _.debounce(function (e) {
            searchText = $(this).val();
            $.ajax({
                type: 'post',
                dataType: 'json',
                url: '/autocomplete',
                data: {
                    searchText: searchText
                },
                success: function (response) {
                    autocompleteBox.html('');
                    if (!autocompleteBox.hasClass('autocomplete--active')) {
                        autocompleteBox.addClass('autocomplete--active');
                    }
                    $.each(response, function (key, value) {
                        autocompleteBox.append(
                            $('<a class="autocomplete__link" href="' + value.Href + '">' + value.Name + '</a>')
                        );
                    });
                }
            })
        }, 700));

        // sort list
        var sortUrl = $(".sortbox").data("url");
        $(".sortbox__byprice").on("click", function () {
            $.ajax({
                type: "POST",
                url: sortUrl,
                data: {
                    sortByPrice: $(this).data('byprice')
                },
                success: function(response) {
                    if (response == 'ok') {
                        window.location.href = sortUrl;
                    }
                }
            });
        });
        $(".sortbox__byname").on("click", function () {
            $.ajax({
                type: "POST",
                url: sortUrl,
                data: {
                    sortByName: $(this).data('byname')
                },
                success: function(response) {
                    if (response == 'ok') {
                        window.location.href = sortUrl;
                    }
                }
            });
        });
        $(".sortbox__perpage").on("click", function () {
            $.ajax({
                type: "POST",
                url: sortUrl,
                data: {
                    perpage: $(this).data('perpage')
                },
                success: function(response) {
                    if (response == 'ok') {
                        window.location.href = sortUrl;
                    }
                }
            });
        });
    })();

    // Firma / Osoba fizyczna
    (function () {
        var orderPage = $('.order-page');
        if (orderPage.length) {
            var selectedOption = $('.fieldCustomerType:checked').val();

            toggleOrderForm(selectedOption, false);

            $('.fieldCustomerType').on('change', function () {
                selectedOption = $(this).val();
                toggleOrderForm(selectedOption, true);
            })
        }
    })();
});

//autocomplete
(function () {
    var searchField = $(".top__search-input");
    var searchText = searchField.val();
    var autocompleteForm = $("form.top__search-form");
    var autocompleteBox = $(".autocomplete");
    var autocompleteLink = $(".autocomplete__link");

    searchField.on('focus', function () {
        if (autocompleteLink.length) {
            autocompleteBox.addClass('autocomplete--active');
        }
    });

    $("body").on('mouseup', function (e) {
        if (!autocompleteForm.is(e.target) && autocompleteForm.has(e.target).length === 0) {
            autocompleteBox.removeClass('autocomplete--active');
        }
    });

    searchField.on('keyup', _.debounce(function (e) {
        searchText = $(this).val();
        $.ajax({
            type: 'post',
            dataType: 'json',
            url: '/autocomplete',
            data: {
                searchText: searchText
            },
            success: function (response) {
                autocompleteBox.html('');
                if (!autocompleteBox.hasClass('autocomplete--active')) {
                    autocompleteBox.addClass('autocomplete--active');
                }
                $.each(response, function (key, value) {
                    autocompleteBox.append(
                        $('<a class="autocomplete__link" href="' + value.Href + '">' + value.Name + '</a>')
                    );
                });
            }
        })
    }, 700))
})();

// Firma / Osoba fizyczna
(function () {
    var orderPage = $('.order-page');
    if (orderPage.length) {
        var selectedOption = $('.fieldCustomerType:checked').val();

        toggleOrderForm(selectedOption, false);

        $('.fieldCustomerType').on('change', function () {
            selectedOption = $(this).val();
            toggleOrderForm(selectedOption, true);
        })
    }
})();

function toggleOrderForm(option, clear) {
    var companyFields = $('.form-company');
    var personFields = $('.form-person');

    if (clear) {
        companyFields.find('input').removeClass('is-invalid').removeClass('is-valid');
        companyFields.find('.valid-feedback').remove();

        personFields.find('input').removeClass('is-invalid').removeClass('is-valid');
        personFields.find('.valid-feedback').remove();
    }

    if (option == 0) {
        companyFields.show();
        companyFields.find('input').attr('required', 'required');

        personFields.hide();
        personFields.find('input').removeAttr('required');

        if (clear) {
            companyFields.find('input').val('');
        }
    }

    if (option == 1) {
        personFields.show();
        personFields.find('input').attr('required', 'required');

        companyFields.hide();
        companyFields.find('input').removeAttr('required');

        if (clear) {
            personFields.find('input').val('');
        }
    }
}

function updateLeasingInstallemnt(period, paymentPrecent, finalPrecent, productPrice, productId) {
    var field = $("#leasingInstallment");
    var loader = $("#leasingLoader");
    var installmentValue = $("#installmentValue");

    $.ajax({
        method: 'post',
        url: '/calculate',
        dataType: 'json',
        data: {
            period: period,
            paymentPrecent: paymentPrecent,
            finalPrecent: finalPrecent,
            productPrice: productPrice,
            productId: productId
        },
        beforeSend: function () {
            loader.addClass('active');
        },
        error: function (jqXHR, textStatus, errorThrown) {
            alert(errorThrown);
        },
        success: function (response) {
            if (!response.isError) {
                installmentValue.val(response['installment']);
                field.text(parseFloat(response['installment']).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.', ','))
            }
            else {
                alert(response.message);
            }
        },
        complete: function () {
            setTimeout(function () {
                loader.removeClass('active');
            }, 500);

        }
    })
}

function imgToSvg(img) {
    img.each(function () {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function (data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });
}

function scalePic(width, height) {
    var scalPicture = $(".pic-" + width + "-" + height);

    if (scalPicture.length) {
        scalPicture.each(function () {
            $(this).height(($(this).width() / width) * height);
        })
    }
}

